import Vue from 'vue'

// Our laravel api url
Vue.prototype.$VUE_APP_API_ENDPOINT ='https://intro-api.cwsdev1.com/api/';
// Vue.prototype.$VUE_APP_API_ENDPOINT ='http://127.0.0.1:8000/api/';


// //Stripe Local Public Key
// Vue.prototype.$pulishableKey ='pk_test_51PXfYBKgKdpywnn7VfvgjcN3FJLXU6ongyx0O5g2taj5edHuyMhVGK5UuP5C6uzT8k4duBo7ynERXO3jtkR68gTc00kgtBkNYD';
Vue.prototype.$pulishableKey ='pk_test_51Q62gV2MLSIIST0fYcPrjAy5IW05YosuTyRXpq2B8r9g0SgiSNFohl5n4BpIB54mrtrUn8SwKpqu7fsX1hSIf8R700wKxlH9Zi';

//Stripe Live Public Key
// Vue.prototype.$pulishableKey ='pk_live_51OwSlYK6tLmOCSGruHLWaHkNqepclgetx6oal2Z3C9iAg1DpkxrdVsoDA8emtmXqEKWhysew3iFpQefm7yKoLvuf00pZwWT9qw';

import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import firebase from 'firebase';
// import { Translator } from 'vue-google-translate';
// Vue.use(Translator)

import vGoogleTranslate from "v-google-translate";
Vue.use(vGoogleTranslate);


import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

//Ckeditor
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use( CKEditor );

Vue.use(require('vue-moment'));

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

//Firebase setup
const configOptions = {
  apiKey: "AIzaSyDwMjsJK-G_lyBvOsGsq_ELOLzG3S2pZhM",
  authDomain: "the-active-intro-2021.firebaseapp.com",
  databaseURL: "https://the-active-intro-2021-default-rtdb.firebaseio.com/",
  projectId: "the-active-intro-2021",
  storageBucket: "the-active-intro-2021.appspot.com",
  messagingSenderId: "481542348965",
  appId: "1:481542348965:web:e63e22e3e46a1b27916d4c",
  measurementId: "G-GKXGLJWFZS"
};


/* Initialize firebase with your configuration */
firebase.initializeApp(configOptions);

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

/* Bind firebase to your Vue instance */
Vue.prototype.$firebase = firebase;
// console.log(firebase.auth());

// firebase.auth().onAuthStateChanged(user => {
//   store.dispatch("fetchUser", user);
//  // console.log(user);
// });

// firebase.auth().onAuthStateChanged(user => {
//   // console.log('user');
//   store.dispatch("fetchUser", user);
// });

export const db = firebase.firestore();

// date issue fix according to firebase
const settings = {
  timestampsInSnapshots: true,
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
};

db.settings(settings);
db.enablePersistence();
// .enablePersistence()
// .catch((err) => {
//   console.log(err);
//     if (err.code == 'failed-precondition') {
//         // Multiple tabs open, persistence can only be enabled
//         // in one tab at a a time.
//         // ...
//     } else if (err.code == 'unimplemented') {
//         // The current browser does not support all of the
//         // features required to enable persistence
//         // ...
//     }
// });
export const storage = firebase.storage();



firebase.auth().onAuthStateChanged((user) => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')

  if (user) {
    store.dispatch("fetchUser", user);

  }
})
