import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    isloggedIn: false
  },
  getters: {
    user(state) {
      return state.user
    },
    isloggedIn(state) {
      return state.isloggedIn
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      console.log(value);
      console.log('Commitction');
      
      state.isloggedIn = value;
      console.log(state.isloggedIn);

      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    UNSET_USER(state, data) {
      console.log('unsetting user');
      state.user.loggedIn = false;
      state.user.data = data;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      console.log('fetchAction');
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email
        });
      } else {
        commit("SET_USER", null);
      }
    },
    logOut({ commit }){
      console.log('logout action');
      commit("UNSET_USER", null);

    }
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
