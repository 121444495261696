var slug = '';
var slug_id = '';

if (localStorage.getItem('slug') !== null) {
    slug = localStorage.getItem("slug");
} else {
    slug = 'united-arab-emirates';
    slug_id = 'TNl3gn4ZKToWzQMd97Vu';
    localStorage.setItem("slug", slug);
    localStorage.setItem("slug_id", slug_id);
}

export default [
    //dashboard
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    //Users
    {
        path: '/users',
        name: 'users-list',
        component: () => import('../../ak-admin-views/users/List'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/users/view/:id',
        name: 'user-view',
        component: () => import('../../ak-admin-views/users/View'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/users/edit/:id',
        name: 'user-edit',
        component: () => import('../../ak-admin-views/users/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/users/create',
        name: 'user-create',
        component: () => import('../../ak-admin-views/users/Create'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    //Categories
    {
        path: '/categories',
        name: 'categories-list',
        component: () => import('../../ak-admin-views/categories/List'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/categories/edit/:id',
        name: 'categories-edit',
        component: () => import('../../ak-admin-views/categories/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/categories/create',
        name: 'categories-create',
        component: () => import('../../ak-admin-views/categories/Create'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Menu for subscription
    {
        path: '/menu',
        name: 'menu-list',
        component: () => import('../../ak-admin-views/menu/List.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/menu/create',
        name: 'menu-create',
        component: () => import('../../ak-admin-views/menu/Create.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/menu/edit/:id',
        name: 'menu-edit',
        component: () => import('../../ak-admin-views/menu/Edit.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    //sub category 
    {
        path: '/subcategory',
        name: 'subcategory-list',
        component: () => import('../../ak-admin-views/subcategory/List.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/subcategory/create',
        name: 'subcategory-create',
        component: () => import('../../ak-admin-views/subcategory/Create.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/subcategory/edit/:id',
        name: 'subcategory-edit',
        component: () => import('../../ak-admin-views/subcategory/Edit.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    //sub Features 
    {
        path: '/subfeatures',
        name: 'subfeatures-list',
        component: () => import('../../ak-admin-views/subfeatures/List.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/subfeatures/create',
        name: 'subfeatures-create',
        component: () => import('../../ak-admin-views/subfeatures/Create.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/subfeatures/edit/:id',
        name: 'subfeatures-edit',
        component: () => import('../../ak-admin-views/subfeatures/Edit.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },

    //Blogs
    //Blog Category
    {
        path: '/blogs/categories',
        name: 'blogs-categories-list',
        component: () => import('../../ak-admin-views/blogs/categories/List'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/blogs/categories/edit/:id',
        name: 'blogs-categories-edit',
        component: () => import('../../ak-admin-views/blogs/categories/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/blogs/categories/create',
        name: 'blogs-categories-create',
        component: () => import('../../ak-admin-views/blogs/categories/Create'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    //Blogs
    {
        path: '/blogs',
        name: 'blogs-list',
        component: () => import('../../ak-admin-views/blogs/List'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/blogs/edit/:id',
        name: 'blogs-edit',
        component: () => import('../../ak-admin-views/blogs/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/blogs/create',
        name: 'blogs-create',
        component: () => import('../../ak-admin-views/blogs/Create'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // SBlogs
    {
        path: '/sblogs/index',
        name: 'sblog-index',
        component: () => import('../../ak-admin-views/sblogs/Index'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/sblogs/edit/:id',
        name: 'sblogs-edit',
        component: () => import('../../ak-admin-views/sblogs/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Countries
    {
        path: '/countries/index',
        name: 'country-index',
        component: () => import('../../ak-admin-views/countries/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/countries/create',
        name: 'countries-create',
        component: () => import('../../ak-admin-views/countries/Create'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/countries/edit/:id',
        name: 'countries-edit',
        component: () => import('../../ak-admin-views/countries/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Contact us
    {
        path: '/contactus/index',
        name: 'contactus-index',
        component: () => import('../../ak-admin-views/contactus/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Subscriptions
    {
        path: '/subscriptions/index',
        name: 'subscription-index',
        component: () => import('../../ak-admin-views/subscriptions/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/subscriptions/create',
        name: 'subscriptions-create',
        component: () => import('../../ak-admin-views/subscriptions/Create.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/subscriptions/edit/:id',
        name: 'subscriptions-edit',
        component: () => import('../../ak-admin-views/subscriptions/Edit.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Subscription Categories
    {
        path: '/subscription_categories/index',
        name: 'subscription-categories-index',
        component: () => import('../../ak-admin-views/subscription_categories/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Companies
    {
        path: '/companies/index',
        name: 'company-index',
        component: () => import('../../ak-admin-views/companies/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/companies/create',
        name: 'company-create',
        component: () => import('../../ak-admin-views/companies/Create.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/companies/edit/:id',
        name: 'company-edit',
        component: () => import('../../ak-admin-views/companies/Edit.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/companies/view/:id',
        name: 'company-view',
        component: () => import('../../ak-admin-views/companies/View.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Offers
    {
        path: '/offers/index',
        name: 'offers-index',
        component: () => import('../../ak-admin-views/offers/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/offers/create',
        name: 'offers-create',
        component: () => import('../../ak-admin-views/offers/Create.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/offers/edit/:id',
        name: 'offers-edit',
        component: () => import('../../ak-admin-views/offers/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Partner
    {
        path: '/partner/index',
        name: 'partner-index',
        component: () => import('../../ak-admin-views/partner/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/partner/create',
        name: 'partner-create',
        component: () => import('../../ak-admin-views/partner/Create'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/partner/edit/:id',
        name: 'partner-edit',
        component: () => import('../../ak-admin-views/partner/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Faqs
    {
        path: '/faqs/index',
        name: 'faq-index',
        component: () => import('../../ak-admin-views/faqs/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/faqs/create',
        name: 'faqs-create',
        component: () => import('../../ak-admin-views/faqs/Create'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/faqs/edit/:id',
        name: 'faqs-edit',
        component: () => import('../../ak-admin-views/faqs/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Pages
    {
        path: '/pages/index',
        name: 'pages-index',
        component: () => import('../../ak-admin-views/pages/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/pages/create/',
        name: 'pages-create',
        component: () => import('../../ak-admin-views/pages/Create'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/pages/edit/:id',
        name: 'pages-edit',
        component: () => import('../../ak-admin-views/pages/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Components
    {
        path: '/components/index',
        name: 'components-index',
        component: () => import('../../ak-admin-views/component/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/components/edit/:id',
        name: 'components-edit',
        component: () => import('../../ak-admin-views/component/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Features
    {
        path: '/features/index',
        name: 'features-index',
        component: () => import('../../ak-admin-views/features/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/features/edit/:id',
        name: 'features-edit',
        component: () => import('../../ak-admin-views/features/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
       // strip query 
       {
        path: '/stripquery',
        name: 'StripQuery',
        component: () => import('../../ak-admin-views/strip/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Links
    {
        path: '/links/index',
        name: 'links-index',
        component: () => import('../../ak-admin-views/links/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/links/edit/:id',
        name: 'links-edit',
        component: () => import('../../ak-admin-views/links/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Inspired
    {
        path: '/inspired/index',
        name: 'inspired-index',
        component: () => import('../../ak-admin-views/inspired/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/inspired/create',
        name: 'inspired-create',
        component: () => import('../../ak-admin-views/inspired/Create'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/inspired/edit/:id',
        name: 'inspired-edit',
        component: () => import('../../ak-admin-views/inspired/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Social Links
    {
        path: '/sociallinks/index',
        name: 'sociallinks-index',
        component: () => import('../../ak-admin-views/sociallinks/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/sociallinks/edit/:id',
        name: 'sociallinks-edit',
        component: () => import('../../ak-admin-views/sociallinks/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Reviews
    {
        path: '/reviews/index',
        name: 'reviews-index',
        component: () => import('../../ak-admin-views/reviews/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/reviews/edit/:id',
        name: 'reviews-edit',
        component: () => import('../../ak-admin-views/reviews/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Orders
    {
        path: '/orders/index',
        name: 'orders-index',
        component: () => import('../../ak-admin-views/orders/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Redeem
    {
        path: '/redeem/index',
        name: 'redeem-index',
        component: () => import('../../ak-admin-views/redeem/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Email Management
    {
        path: '/email_management/index',
        name: 'email_management-index',
        component: () => import('../../ak-admin-views/email_management/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/email_management/edit/:id',
        name: 'email_management-edit',
        component: () => import('../../ak-admin-views/email_management/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // PSetting
    {
        path: '/psetting/index',
        name: 'psetting-index',
        component: () => import('../../ak-admin-views/psetting/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/psetting/edit/:id',
        name: 'psetting-edit',
        component: () => import('../../ak-admin-views/psetting/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Address
    {
        path: '/address/index',
        name: 'address-index',
        component: () => import('../../ak-admin-views/address/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/address/edit/:id',
        name: 'address-edit',
        component: () => import('../../ak-admin-views/address/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },

    //Affiliate Marketing
    //Affiliate Sharing
    {
        path: '/affiliate/affiliate-sharing',
        name: 'affiliate-sharing-list',
        component: () => import('../../ak-admin-views/affiliate/affiliate-sharing/List'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/affiliate/affiliate-sharing/edit/:id',
        name: 'affiliate-sharing-edit',
        component: () => import('../../ak-admin-views/affiliate/affiliate-sharing/Edit'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/affiliate/affiliate-sharing/create',
        name: 'affiliate-sharing-create',
        component: () => import('../../ak-admin-views/affiliate/affiliate-sharing/Create'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    //Affiliate users
    {
        path: '/affiliate/affiliate-users',
        name: 'affiliate-users-list',
        component: () => import('../../ak-admin-views/affiliate/users/List'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    //Affiliate Transfer Request
    {
        path: '/affiliate/transfer-request',
        name: 'transfer-request-list',
        component: () => import('../../ak-admin-views/affiliate/transfer/List'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    // Cancel Orders
    {
        path: '/cancel/orders/',
        name: 'cancel-orders',
        component: () => import('../../ak-admin-views/orders/CancelOrder.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/currency',
        name: 'currency',
        component: () => import('../../ak-admin-views/currency/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/currency/create',
        name: 'currency-create',
        component: () => import('../../ak-admin-views/currency/Create.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/currency/edit/:id',
        name: 'currency-edit',
        component: () => import('../../ak-admin-views/currency/Edit.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },

    {
        path: '/coupon',
        name: 'coupon',
        component: () => import('../../ak-admin-views/coupon/Index.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/coupon/create',
        name: 'coupon-create',
        component: () => import('../../ak-admin-views/coupon/Create.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },
    {
        path: '/coupon/edit/:id',
        name: 'coupon-edit',
        component: () => import('../../ak-admin-views/coupon/Edit.vue'),
        meta: { requiresAuth: true, layout: 'withSides', roles: [1] },
    },

    // ################ FRONT STORE ##############################
    {
        path: "/",
        redirect: '/en/' + slug,
        name: 'Home',
        component: () => import('../../ak-admin-views/front-store/Home.vue'),
        meta: { layout: 'front', roles: [1, 2] },

    },
    {
        path: "/en/:slug",
        name: 'Home',
        component: () => import('../../ak-admin-views/front-store/Home.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/signin",
        name: 'signin',
        component: () => import('../../ak-admin-views/front-store/Signin.vue'),
        meta: { layout: 'front', roles: [1, 2] },

    },

    {
        path: "/registration",
        name: 'registration',
        component: () => import('../../ak-admin-views/front-store/Register.vue'),
        meta: { layout: 'front', roles: [1, 2] },

    },

    {
        path: '/blogs/list',
        name: 'front-blogs',
        component: () => import('../../ak-admin-views/front-store/Blog.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: '/single-blog/:id',
        name: 'singleBlog',
        component: () => import('../../ak-admin-views/front-store/SingleBlog.vue'),
        meta: { layout: 'front', roles: [1, 2] },

    },

    {
        path: '/blog-category/:id',
        name: 'blogCategory',
        component: () => import('../../ak-admin-views/front-store/BlogCategory.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: '/cart',
        name: 'cart',
        component: () => import('../../ak-admin-views/front-store/Cart.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: '/insider',
        name: 'insider',
        component: () => import('../../ak-admin-views/front-store/Insider.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/search",
        name: 'Search',
        component: () => import('../../ak-admin-views/front-store/Search.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: '/explore',
        name: 'explore',
        component: () => import('../../ak-admin-views/front-store/Explore.vue'),
        meta: { layout: 'front', roles: [1, 2], requiresAuth: true },
    },

    {
        name: "Subscription",
        path: '/subscriptions/:id',
        component: () => import('../../ak-admin-views/front-store/Subscription.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        name: 'singleCompany',
        path: '/single-company/:id',
        component: () => import('../../ak-admin-views/front-store/SingleCompany.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        name: 'companyOffers',
        path: '/offers/:id',
        component: () => import('../../ak-admin-views/front-store/Offers.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        name: 'offerDetail',
        path: '/offer-detail/:id',
        component: () => import('../../ak-admin-views/front-store/OfferDetail.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: '/Profile',
        name: 'userProfile',
        component: () => import('../../ak-admin-views/front-store/Profile.vue'),
        meta: { layout: 'front', roles: [1, 2], requiresAuth: true },
    },

    {
        path: "/forgot",
        name: 'Forgot',
        component: () => import('../../ak-admin-views/front-store/Forgot.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: '/thankyou',
        name: 'thankyou',
        component: () => import('../../ak-admin-views/front-store/ThankYou.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/success/:id/:product_id",
        name: 'Success',
        component: () => import('../../ak-admin-views/front-store/Success.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/about",
        name: 'About',
        component: () => import('../../ak-admin-views/front-store/About.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/terms-and-conditions",
        name: 'termsAndConditions',
        component: () => import('../../ak-admin-views/front-store/TermsAndConditions.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/privacy-policy",
        name: 'privacyPolicy',
        component: () => import('../../ak-admin-views/front-store/PrivacyPolicy.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/newsthank",
        name: 'newsthank',
        component: () => import('../../ak-admin-views/front-store/NewsThank.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/contact",
        name: 'Contact',
        component: () => import('../../ak-admin-views/front-store/Contact.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/business-partner",
        name: 'business',
        component: () => import('../../ak-admin-views/front-store/Business.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/otpverify",
        name: 'otpverify',
        component: () => import('../../ak-admin-views/front-store/OtpVerify.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        name: 'faqs',
        path: '/faqs',
        component: () => import('../../ak-admin-views/front-store/Faq.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: '/business-directory',
        name: 'business-directory',
        component: () => import('../../ak-admin-views/front-store/BusinessDirectory.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/directory/:slug",
        name: 'directory',
        component: () => import('../../ak-admin-views/front-store/Directory.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: "/affiliate/",
        name: 'affiliate',
        component: () => import('../../ak-admin-views/front-store/Affiliate.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },

    {
        path: '/affiliate/profile',
        redirect: '/affiliate/profile/' + slug ,
        name: 'affiliateProfile',
        component: () => import('../../ak-admin-views/front-store/AffiliateProfile.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },
    {
        path: "/affiliate/profile/:slug",
        name: 'affiliateProfile',
        component: () => import('../../ak-admin-views/front-store/AffiliateProfile.vue'),
        meta: { layout: 'front', roles: [1, 2], requiresAuth: true  },
    },
    {
        name: "AffiliateInstructions",
        path: '/affiliate/instructions',
        component: () => import('../../ak-admin-views/front-store/AffiliateInstructions.vue'),
        meta: { layout: 'front', roles: [1, 2] },
    },
    {
        path: '/affiliate/account',
        name: 'AffiliateAccount',
        component: () => import('../../ak-admin-views/front-store/AffiliateAccount.vue'),
        meta: { layout: 'front', roles: [1, 2], requiresAuth: true },
    },
    {
        path: '/affiliate/account/:affiliate_id',
        name: 'AffiliateAccount',
        component: () => import('../../ak-admin-views/front-store/AffiliateAccount.vue'),
        meta: { layout: 'front', roles: [1, 2], requiresAuth: true },
    },
    {
        path: '/affiliate/payment',
        name: 'AffiliatePayment',
        component: () => import('../../ak-admin-views/front-store/AffiliatePayment.vue'),
        meta: { layout: 'front', roles: [1, 2], requiresAuth: true },
    },
    {
        path: '/affiliate/selling',
        name: 'AffiliateSelling',
        component: () => import('../../ak-admin-views/front-store/AffiliateSelling.vue'),
        meta: { layout: 'front', roles: [1, 2], requiresAuth: true },
    },

 
]

